import React from "react";
import {
  Box,
  Flex,
  Grid,
  Link,
  Text,
  Icon,
  useTheme,
  useColorModeValue,
  IconProps,
} from "@chakra-ui/react";
import { ReactComponent as WhiteNeopinIcon } from "assets/image/neopinLogo/logo_neopin_white.svg";
import {
  APPLE_STORE_LINK,
  GOOGLE_PLAY_STORE_LINK,
  LANDING_PAGE_URL,
  NEOPIN_DAPP_LINK,
} from "../config/constants/common";
import { ColorWhiteNeopinIcon } from "./WhiteNeopinIcon";
import { useThemeColors } from "../hooks/useThemeColors";

const Footer = () => {
  const { color } = useThemeColors();

  const MENU: {
    title: string;
    links: { title: string; link: string; icon?: React.ReactNode }[];
  }[] = [
    {
      title: "Products",
      links: [
        {
          title: "Staking | Liquid Staking",
          link: `${NEOPIN_DAPP_LINK}/staking`,
        },
        { title: "Derivatives", link: `${NEOPIN_DAPP_LINK}/derivatives` },
        { title: "Index", link: `${NEOPIN_DAPP_LINK}/index` },
        { title: "Pool", link: `${NEOPIN_DAPP_LINK}/pool` },
        { title: "Swap", link: `${NEOPIN_DAPP_LINK}/swap` },
        { title: "EVM Bridge", link: `${NEOPIN_DAPP_LINK}/bridge` },
        { title: "Finschia Bridge", link: `${NEOPIN_DAPP_LINK}/bridge/fnsa` },
      ],
    },
    {
      title: "Developers",
      links: [
        { title: "Documentation", link: "https://docs.neopin.io/" },
        {
          title: "Neopin Connect",
          link: "https://docs.neopin.io/developers/neopin-connect",
        },
        {
          title: "White Paper",
          link: "https://app.neopin.io/VisionPaper_V2.2.pdf",
        },
      ],
    },
    {
      title: "About Us",
      links: [
        { title: "About NEOPIN", link: "https://docs.neopin.io/" },
        { title: "Partners", link: `${LANDING_PAGE_URL}/partners` },
      ],
    },
    {
      title: "Support",
      links: [
        { title: "Notices", link: `${NEOPIN_DAPP_LINK}/notice#notice` },
        { title: "FAQ", link: `${NEOPIN_DAPP_LINK}/faq` },
        {
          title: "Contact Us",
          link: "https://docs.neopin.io/links/contact-us",
        },
      ],
    },
    {
      title: "App Downloads",
      links: [
        {
          title: "App Store",
          link: APPLE_STORE_LINK,
          icon: (
            <Icon viewBox="0 0 20 20" fill="none" boxSize={5}>
              <path
                d="M14.2106 16.9C13.3939 17.6917 12.5023 17.5667 11.6439 17.1917C10.7356 16.8083 9.90225 16.7917 8.94392 17.1917C7.74392 17.7083 7.11059 17.5583 6.39392 16.9C2.32725 12.7083 2.92725 6.325 7.54392 6.09167C8.66892 6.15 9.45225 6.70833 10.1106 6.75833C11.0939 6.55833 12.0356 5.98333 13.0856 6.05833C14.3439 6.15833 15.2939 6.65833 15.9189 7.55833C13.3189 9.11667 13.9356 12.5417 16.3189 13.5C15.8439 14.75 15.2273 15.9917 14.2023 16.9083L14.2106 16.9ZM10.0273 6.04167C9.90225 4.18333 11.4106 2.65 13.1439 2.5C13.3856 4.65 11.1939 6.25 10.0273 6.04167Z"
                fill={color.text.primary}
              />
            </Icon>
          ),
        },
        {
          title: "Google Play",
          link: GOOGLE_PLAY_STORE_LINK,
          icon: (
            <Icon viewBox="0 0 20 20" fill="none" boxSize={5}>
              <path
                d="M4 3.85914V16.1402C4.00008 16.1669 4.00803 16.1929 4.02284 16.215C4.03766 16.2372 4.05868 16.2545 4.08328 16.2647C4.10788 16.275 4.13495 16.2777 4.16111 16.2726C4.18727 16.2676 4.21134 16.2549 4.23031 16.2362L10.625 10L4.23031 3.76321C4.21134 3.7445 4.18727 3.73181 4.16111 3.72673C4.13495 3.72165 4.10788 3.72441 4.08328 3.73465C4.05868 3.7449 4.03766 3.76218 4.02284 3.78433C4.00803 3.80647 4.00008 3.8325 4 3.85914ZM13.3063 7.43753L5.28813 3.02009L5.28313 3.01728C5.14501 2.94228 5.01376 3.12915 5.12688 3.2379L11.4122 9.24782L13.3063 7.43753ZM5.12751 16.7621C5.01376 16.8708 5.14501 17.0577 5.28376 16.9827L5.28876 16.9799L13.3063 12.5625L11.4122 10.7516L5.12751 16.7621ZM16.5432 9.21876L14.3041 7.98565L12.1988 10L14.3041 12.0134L16.5432 10.7812C17.1523 10.4447 17.1523 9.55532 16.5432 9.21876Z"
                fill={color.text.primary}
              />
            </Icon>
          ),
        },
      ],
    },
  ];

  return (
    <Box
      as="footer"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      w="100%"
      mt="40px"
      maxWidth={{ base: "100%", lg: "1400px" }}
      padding={{ base: "20px 20px 40px 20px", md: "40px auto 60px auto" }}
      margin={{ lg: "80px auto 0px auto" }}
    >
      <Box
        w="100%"
        display="flex"
        flexDirection="column"
        boxSizing="border-box"
        maxWidth={{ lg: "1352px" }}
        padding={{ lg: "40px auto 60px auto" }}
      >
        <Grid
          display={{ base: "grid", lg: "flex" }}
          templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(5, 1fr)" }}
          gap="20px"
          margin={{ base: "0px 0px 24px 0px", lg: "0px 0px 52px 0px" }}
          justifyContent={{ lg: "space-between" }}
        >
          {MENU.map(({ title, links }) => (
            <Box
              key={title}
              display="flex"
              flexDirection="column"
              gap={{ base: "8px", lg: "12px" }}
            >
              <Text
                color={color.text.tertiary}
                textStyle={{ base: "caption2", lg: "caption2" }}
              >
                {title}
              </Text>
              <Box display="flex" flexDirection="column" gap="8px">
                {links.map((link) => (
                  <Link
                    key={link.title}
                    href={link.link}
                    isExternal
                    display="flex"
                    alignItems="center"
                    gap="4px"
                    color={color.text.primary}
                    textStyle={{ base: "label4", lg: "label4" }}
                  >
                    {link.icon}
                    {link.title}
                  </Link>
                ))}
              </Box>
            </Box>
          ))}
        </Grid>
        <Flex
          w="100%"
          boxSizing="border-box"
          flexDirection={{ base: "column", lg: "row" }}
          gap="12px"
          alignItems={{ base: "flex-start", lg: "center" }}
          justifyContent={{ lg: "space-between" }}
          p={{ base: "12px 30px 12px 0px", lg: "16px 0px" }}
        >
          <Flex
            flexDirection="row"
            alignItems="center"
            gap="12px"
            align={"center"}
          >
            <ColorWhiteNeopinIcon />
            <Flex
              gap="5px"
              color={color.text.tertiary}
              fontSize="12px"
              fontWeight="medium"
            >
              <Link href="https://static.neopin.io/terms.html" isExternal>
                Terms of Service
              </Link>
              |
              <Link href="https://static.neopin.io/privacy.html" isExternal>
                Privacy Policy
              </Link>
            </Flex>
          </Flex>
          <Flex flexDirection="row" gap="12px">
            <Link
              href="https://discord.gg/neopinofficial"
              isExternal
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="36px"
              h="36px"
              bg={color.icon.vectorwhite}
              borderRadius="50%"
            >
              <Icon viewBox="0 0 20 20" fill="none" boxSize={5}>
                <path
                  d="M16.3483 4.44109C15.1871 3.92456 13.9299 3.54966 12.6204 3.33305C12.6089 3.3327 12.5975 3.33475 12.5869 3.33907C12.5764 3.34339 12.5669 3.34986 12.5593 3.35804C12.4021 3.63297 12.2188 3.99121 12.0965 4.26614C10.7075 4.06619 9.29496 4.06619 7.90596 4.26614C7.78373 3.98288 7.60039 3.63297 7.43452 3.35804C7.42579 3.34138 7.39959 3.33305 7.3734 3.33305C6.06384 3.54966 4.8154 3.92456 3.64552 4.44109C3.63679 4.44109 3.62806 4.44942 3.61933 4.45775C1.24466 7.84853 0.589884 11.1477 0.912908 14.4135C0.912908 14.4301 0.921639 14.4468 0.939099 14.4551C2.51057 15.5548 4.02093 16.2213 5.51383 16.6629C5.54002 16.6712 5.56621 16.6629 5.57494 16.6462C5.92416 16.188 6.23845 15.7048 6.50909 15.1966C6.52655 15.1633 6.50909 15.13 6.47417 15.1216C5.97654 14.9383 5.5051 14.7217 5.04239 14.4718C5.00746 14.4551 5.00746 14.4051 5.03366 14.3802C5.12969 14.3135 5.22572 14.2385 5.32176 14.1719C5.33922 14.1552 5.36541 14.1552 5.38287 14.1635C8.38613 15.4715 11.6251 15.4715 14.5934 14.1635C14.6109 14.1552 14.6371 14.1552 14.6546 14.1719C14.7506 14.2469 14.8466 14.3135 14.9427 14.3885C14.9776 14.4135 14.9776 14.4635 14.9339 14.4801C14.4799 14.7384 13.9998 14.9467 13.5021 15.13C13.4672 15.1383 13.4585 15.1799 13.4672 15.2049C13.7466 15.7131 14.0609 16.1963 14.4014 16.6546C14.4276 16.6629 14.4538 16.6712 14.4799 16.6629C15.9816 16.2213 17.4919 15.5548 19.0634 14.4551C19.0809 14.4468 19.0896 14.4301 19.0896 14.4135C19.4737 10.6395 18.4523 7.36532 16.3832 4.45775C16.3744 4.44942 16.3657 4.44109 16.3483 4.44109ZM6.96307 12.4223C6.06384 12.4223 5.31303 11.6309 5.31303 10.6561C5.31303 9.68138 6.04638 8.88992 6.96307 8.88992C7.8885 8.88992 8.62185 9.68971 8.61312 10.6561C8.61312 11.6309 7.87977 12.4223 6.96307 12.4223ZM13.0482 12.4223C12.1489 12.4223 11.3981 11.6309 11.3981 10.6561C11.3981 9.68138 12.1315 8.88992 13.0482 8.88992C13.9736 8.88992 14.7069 9.68971 14.6982 10.6561C14.6982 11.6309 13.9736 12.4223 13.0482 12.4223Z"
                  fill={color.icon.vectorblack}
                />
              </Icon>
            </Link>
            <Link
              href="https://twitter.com/neopinofficial"
              isExternal
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="36px"
              h="36px"
              bg={color.icon.vectorwhite}
              borderRadius="50%"
            >
              <Icon viewBox="0 0 20 20" fill="none" boxSize={5}>
                <path
                  d="M14.7899 2.5H17.3457L11.7624 8.85417L18.3307 17.5H13.1874L9.1599 12.2558L4.55073 17.5H1.9924L7.9649 10.7042L1.66406 2.5H6.9374L10.5791 7.29333L14.7899 2.5ZM13.8932 15.9767H15.3099L6.1674 3.94333H4.6474L13.8932 15.9767Z"
                  fill={color.icon.vectorblack}
                />
              </Icon>
            </Link>
            <Link
              href="https://medium.com/neopin-official"
              isExternal
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="36px"
              h="36px"
              bg={color.icon.vectorwhite}
              borderRadius="50%"
            >
              <Icon viewBox="0 0 20 20" fill="none" boxSize={5}>
                <path
                  d="M6 4.16699C7.5913 4.16699 9.11742 4.78157 10.2426 5.87554C11.3679 6.9695 12 8.45323 12 10.0003C12 11.5474 11.3679 13.0312 10.2426 14.1251C9.11742 15.2191 7.5913 15.8337 6 15.8337C4.4087 15.8337 2.88258 15.2191 1.75736 14.1251C0.632141 13.0312 0 11.5474 0 10.0003C0 8.45323 0.632141 6.9695 1.75736 5.87554C2.88258 4.78157 4.4087 4.16699 6 4.16699ZM15 5.13921C16.5 5.13921 17.5 7.31602 17.5 10.0003C17.5 12.6846 16.5 14.8614 15 14.8614C13.5 14.8614 12.5 12.6846 12.5 10.0003C12.5 7.31602 13.5 5.13921 15 5.13921ZM19 5.62533C19.38 5.62533 19.712 6.42935 19.88 7.80894L19.927 8.23963L19.946 8.4681L19.976 8.94838L19.986 9.20019L19.998 9.72616L20 10.0003L19.998 10.2745L19.986 10.8005L19.976 11.0532L19.946 11.5325L19.926 11.761L19.881 12.1917C19.712 13.5723 19.381 14.3753 19 14.3753C18.62 14.3753 18.288 13.5713 18.12 12.1917L18.073 11.761C18.0661 11.6849 18.0598 11.6087 18.054 11.5325L18.024 11.0523C18.0201 10.9684 18.0168 10.8844 18.014 10.8005L18.002 10.2745V9.72616L18.014 9.20019L18.024 8.94741L18.054 8.4681L18.074 8.23963L18.119 7.80894C18.288 6.42838 18.619 5.62533 19 5.62533Z"
                  fill={color.icon.vectorblack}
                />
              </Icon>
            </Link>
            <Link
              href="https://t.me/neopin_eng"
              isExternal
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="36px"
              h="36px"
              bg={color.icon.vectorwhite}
              borderRadius="50%"
            >
              <Icon viewBox="0 0 20 20" fill="none" boxSize={5}>
                <path
                  d="M17.2058 3.01014L2.44229 8.78013C1.43475 9.19028 1.44058 9.75993 2.25744 10.014L6.04781 11.2123L14.8176 5.6044C15.2323 5.34869 15.6112 5.48625 15.2997 5.76643L8.19447 12.2656H8.1928L8.19447 12.2664L7.933 16.2262C8.31604 16.2262 8.48507 16.0481 8.6999 15.838L10.541 14.0235L14.3705 16.8903C15.0766 17.2845 15.5837 17.0819 15.7594 16.2279L18.2733 4.22034C18.5306 3.17471 17.8794 2.70126 17.2058 3.01014Z"
                  fill={color.icon.vectorblack}
                />
              </Icon>
            </Link>
          </Flex>
        </Flex>
        <Link
          href="https://static.neopin.io/terms.html"
          isExternal
          _hover={{ textDecoration: "none" }}
        >
          <Text
            color={color.text.secondary}
            fontSize="11px"
            mt="12px"
            lineHeight={"14px"}
            opacity={0.6}
            letterSpacing={"0px"}
          >
            Disclaimer: This content is for informational purposes only and is
            not legal, tax, investment, financial, or other advice. You should
            not take, or refrain from taking, any action based on any
            information contained herein, or any other information that we make
            available at any time, including blog posts, data, articles, links
            to third-party content, discord content, news feeds, tutorials,
            tweets, and videos. Before you make any financial, legal, technical,
            or other decisions, you should seek independent professional advice
            from a licensed and qualified individual in the area for which such
            advice would be appropriate. This information is not intended to be
            comprehensive or address all aspects of NEOPIN or its products. 
            <br />
            <br />
            NEOPIN is strictly prohibited to U.S. persons, regardless of IP
            address, and to users acting directly or indirectly on behalf of any
            U.S. person who is located, organized, established, or ordinarily
            resident in the U.S. or any country where the use of the NEOPIN or
            any country where the use of the NEOPIN Contracts is illegal,
            prohibited, or requires any authorization or license, including, but
            not limited to, users who violate applicable laws and regulations,
            including anti-money laundering regulations, and users who are
            otherwise prohibited under NEOPIN’s Client Agreement, including, but
            not limited to, users who are terrorists or members of terrorist
            organizations. We also strictly prohibit the use of methods to
            conceal your residence and location, such as virtual private
            networks (VPNs). To use this product, every user, on behalf of
            oneself, must read, understand and agree on the Disclaimer. If the
            user falsely agrees and uses this product, the user is solely
            responsible for any legal disadvantages that may result, and NEOPIN
            has no liability.
          </Text>
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
