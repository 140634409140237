import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Icon,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FaSun, FaMoon } from "react-icons/fa";
import { useThemeColors } from "../hooks/useThemeColors";

const ThemeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [theme, setTheme] = useState<"light" | "dark" | undefined>(undefined);

  useEffect(() => {
    setTheme(colorMode as "light" | "dark");
  }, [colorMode]);

  const onClickToggle = () => {
    toggleColorMode();
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  const ToggleIcon = useColorModeValue(IC_DARK, IC_LIGHT);
  const { color } = useThemeColors();

  return (
    <Box
      as="div"
      onClick={onClickToggle}
      display="inline-flex"
      alignItems="center"
      flexShrink="0"
      width="46px"
      height="28px"
      boxSizing="border-box"
      border="1px solid"
      borderColor={color.border.primary}
      borderRadius="9999px"
      backgroundColor={color.background.primary}
      cursor="pointer"
      transition="all 0.2s"
    >
      <Flex
        as={motion.div}
        alignItems="center"
        justifyContent="center"
        width="24px"
        height="24px"
        borderRadius="9999px"
        backgroundColor={color.icon.vectorwhite}
        animate={{ x: theme === "dark" ? 0 : 18 }}
        // transition={{ type: "spring", stiffness: 300, damping: 20 }}
        transition=""
      >
        {theme !== "dark" ? (
          <IC_LIGHT color={color.icon.vectorblack} />
        ) : (
          <IC_DARK color={color.icon.vectorblack} />
        )}
        {/* <Icon as={ToggleIcon} color={color.icon.vectorblack} /> */}
      </Flex>
    </Box>
  );
};

export default ThemeToggle;
const IC_DARK = ({ color }: { color: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00439 14C3.1437 14 0 10.8516 0 6.98521C0 3.35815 2.71627 0.35707 6.31957 0.00264821C6.54938 -0.0203662 6.76999 0.108514 6.86191 0.315644C6.95843 0.527376 6.90787 0.775932 6.74241 0.93243C5.86456 1.76095 5.38198 2.87945 5.38198 4.0762C5.38198 6.46049 7.32151 8.4029 9.70227 8.4029C11.003 8.4029 12.2163 7.82294 13.0436 6.81491C13.1907 6.6354 13.4343 6.57096 13.6503 6.65381C13.8663 6.73206 14.0088 6.94379 13.9996 7.17393C13.8939 10.9989 10.8191 13.9908 7.00439 13.9908V14Z"
      fill={color}
    />
  </svg>
);
const IC_LIGHT = ({ color }: { color: string }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17024 13.3202C6.71879 13.3202 4.7251 11.3265 4.7251 8.87508C4.7251 6.42363 6.71879 4.42993 9.17024 4.42993C11.6217 4.42993 13.6154 6.42363 13.6154 8.87508C13.6154 11.3265 11.6217 13.3202 9.17024 13.3202Z"
      fill={color}
    />
    <path
      d="M9.17004 2.74275C8.92414 2.74275 8.72363 2.54224 8.72363 2.30013V0.442623C8.72363 0.196721 8.92414 0 9.17004 0C9.41594 0 9.61266 0.196721 9.61266 0.442623V2.29634C9.61266 2.54224 9.41216 2.73897 9.17004 2.73897V2.74275Z"
      fill={color}
    />
    <path
      d="M13.5925 5.00131C13.479 5.00131 13.3655 4.95969 13.2785 4.87268C13.1044 4.69866 13.1044 4.41871 13.2785 4.24469L14.8712 2.652C15.0452 2.47798 15.3251 2.47798 15.4991 2.652C15.6732 2.82602 15.6732 3.10597 15.4991 3.28L13.9065 4.87268C13.8194 4.95969 13.706 5.00131 13.5925 5.00131Z"
      fill={color}
    />
    <path
      d="M4.03671 5.00131C3.92322 5.00131 3.80973 4.95969 3.72271 4.87268L2.13003 3.28C1.95601 3.10597 1.95601 2.82602 2.13003 2.652C2.30405 2.47798 2.584 2.47798 2.75802 2.652L4.35071 4.24469C4.52473 4.41871 4.52473 4.69866 4.35071 4.87268C4.2637 4.95969 4.15021 5.00131 4.03671 5.00131Z"
      fill={color}
    />
    <path
      d="M8.81469 17.6861C8.56879 17.6861 8.37207 17.4856 8.37207 17.2397V15.3898C8.37207 15.1439 8.57257 14.9434 8.81469 14.9434C9.05681 14.9434 9.2611 15.1439 9.2611 15.3898V17.2435C9.2611 17.4894 9.06059 17.6861 8.81469 17.6861Z"
      fill={color}
    />
    <path
      d="M2.79949 15.1663C2.686 15.1663 2.57251 15.1247 2.4855 15.0377C2.31147 14.8637 2.31147 14.5837 2.4855 14.4097L4.07818 12.817C4.25221 12.643 4.53216 12.643 4.70618 12.817C4.8802 12.9911 4.8802 13.271 4.70618 13.445L3.11349 15.0377C3.02648 15.1247 2.91299 15.1663 2.79949 15.1663Z"
      fill={color}
    />
    <path
      d="M15.5411 15.1663C15.4276 15.1663 15.3141 15.1247 15.2271 15.0377L13.6344 13.445C13.4604 13.271 13.4604 12.9911 13.6344 12.817C13.8084 12.643 14.0884 12.643 14.2624 12.817L15.8551 14.4097C16.0291 14.5837 16.0291 14.8637 15.8551 15.0377C15.7681 15.1247 15.6546 15.1663 15.5411 15.1663Z"
      fill={color}
    />
    <path
      d="M2.44388 9.32153H0.442623C0.196721 9.32153 0 9.12102 0 8.8789C0 8.63678 0.196721 8.43628 0.442623 8.43628H2.44388C2.68979 8.43628 2.89029 8.63678 2.89029 8.8789C2.89029 9.12102 2.68979 9.32153 2.44388 9.32153Z"
      fill={color}
    />
    <path
      d="M17.5534 9.32153H15.1814C14.9355 9.32153 14.7388 9.12102 14.7388 8.8789C14.7388 8.63678 14.9393 8.43628 15.1814 8.43628H17.5534C17.7993 8.43628 17.996 8.63678 17.996 8.8789C17.996 9.12102 17.7955 9.32153 17.5534 9.32153Z"
      fill={color}
    />
  </svg>
);
